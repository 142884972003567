
import { mapGetters } from 'vuex'
import cms from '~/mixins/common/cms'
import playGames from '~/mixins/common/play-games'
export default {
    name: 'HomeSlider',
    mixins: [cms, playGames],
    data() {
        return {
            interval: 6000,
            imageWidth: 0,
            imageHeight: 0,
            sliderModel: 0,
        }
    },
    computed: {
        slider() {
            return this.$store.state.settings.slider
        },
        ...mapGetters({
            mobileSlider: 'cms/mobileSlider',
        }),
    },
    methods: {
        onNextTrick(evt) {
            if (this.sliderModel + 1 < this.mobileSlider?.items?.length) {
                this.sliderModel += 1
            } else {
                this.sliderModel = 0
            }
        },
        onPrevTrick(evt) {
            if (this.sliderModel - 1 >= 0) {
                this.sliderModel -= 1
            } else {
                this.sliderModel = this.mobileSlider?.items?.length - 1
            }
        },
        onSlideChange(slideNumber) {
            this.interval =
                this.slider[slideNumber]?.carousel_seconds * 1000 || 1000
        },
        doManualChangeSlider(index) {
            this.sliderModel = index
        },
        onResize() {
            const screenWidth = window.innerWidth
            // since the slider size is 1320x450, so I have an formula to calculate the size:
            const height = (screenWidth * 450) / 1320

            this.imageWidth = screenWidth
            this.imageHeight = height
        },
    },
}
